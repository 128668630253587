.about-section-4.vision{
  background-color: #f0f1f0;
}
.container.vision {
    margin-top: 20px;
    padding: 40px 10px 60px 10px;
    max-width: 1030px;
    
  
    .believe{
      transform: rotate(-90deg);
      position: absolute;
      margin-top: 150px;
      margin-left: -150px;
      font-size: 4.0em;
      color: #1d3c5a;
    }
    .title{
      border-bottom: 2px solid #1d3c5a;
      font-size: 5em;
      margin-bottom: 20px;
      font-size: 4.5em;
    }
    .par{
      font-size: 1.3em;
      max-width: 600px;
      margin-left: 382px;
      position: absolute;
      margin-top: -94px;
      color: #1d3c5a;
      span{
        color: #45c1ed;
        font-weight: 700;
      }
    }
    .list{
      padding-left: 10px;
      margin-top: 0px;
      padding-left: 90px;
      font-size: 1.3em;
      line-height: 33px;

      li {
        list-style-type: none;
        color: #1d3c5a;
  
        span{
          color: #45c1ed;
          font-weight: 700;
        }
      }
    }
}
  
// @media only screen and (max-width: 1220px) {
//     .container.vision {
//       .believe{
//         margin-top: 125px;
//         margin-left: -110px;
//       }
//     }
// }
  
@media only screen and (max-width: 989px) {
    .container.vision {
      padding: 20px 10px 20px 10px;
      .title{
        text-align: center;
        font-size: 2.5em;
        margin-bottom: 2px;
      }
      .believe{
        transform: none;
        position: relative;
        padding: 0;
        // padding-left: 60px;
        margin-top: 30px;
        margin-bottom: 0px;
        margin-left: 0px;
        text-align: left;
        font-size: 1.5em;
        width: 100%;
      }
  
      .par{
        max-width: 100%;
        margin-top: 10px;
        position: relative;
        margin-left: 0;
        text-align: center;
        font-size: 1.0em;
        line-height: 24px;
      }
  
      .list {
        padding-left: 10px;
        margin-top: 0px;
        padding-left: 0px;
        font-size: 1.2em;
      }
  
    }
}
  
