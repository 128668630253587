html, body {
  overflow-x: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &_title {
    text-align: center;
    font-size: 55px;
    margin-bottom: 70px;
    font-weight: 500;
  }

  .link_list_wrap {
    display: grid;
    gap: 30px;

    .link_list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      gap: 70px;

      .link_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 135px;

        &_button {
          border: none;
          outline: none;
          background: transparent;
          padding: 5px;
        }

        .image_wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 120px;
          border-radius: 50%;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
          border: 1px solid #f9f6f6;
          margin-bottom: 20px;
          background: white;
          cursor: pointer;

          & .scaled {
            transform: scale(1.8);
          }

          &:hover {
            background: linear-gradient(45deg, #272d83, #009bc5);
            box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.1);
            border: 1px solid #009bc5;

            img {
              filter: brightness(0) invert(1);
            }
          }

          &.active {
            background: linear-gradient(45deg, #272d83, #009bc5);
            box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.1);
            border: 1px solid #009bc5;

            img {
              filter: brightness(0) invert(1);
            }
          }

          img {
            width: 50%;
            object-fit: contain;
          }

        }

        .image_title {
          font-size: 20px;
          font-weight: 600;
          // width: 200px;
          overflow-wrap: break-word;
          text-align: center;
        }

        .link_item_description {
          display: flex;
          margin-top: 20px;
          background: grey;
          color: white;
          border-radius: 5px;
          padding: 5px 10px;
          text-align: center;
          font-size: 12px;
        }
      }
    }



    .link_list.warranty{

      grid-template-columns: repeat(6, 1fr);

      @media screen and (max-width: 1300px) {
        grid-template-columns: repeat(3, 1fr);
      }
    }


    .link_list.promot {
      display: flex;
      z-index: 1;
      margin-left: -2px;
      grid-template-columns: none;
      gap: 38px;
      max-height: 150px;

      .link_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90px;
        flex-wrap: nowrap;

        .image_wrap{
          width: 80px;
          height: 80px;
          margin-bottom: 3px;
        }

        .image_title {
          font-size: 10px;
          display: flex;
          color: #224c74;

          font-size: 0.9em !important;
          line-height: 20px !important;
        }
      }
    }
  }

  .link_item.mobile {
    display: none !important;
  }

  @media screen and (min-width: 1600px) {
    &_title {
      font-size: 75px;
    }
    .link_list_wrap {
      gap: min(4vw, 4vh);

      .link_list {
        gap: min(4vw, 4vh);

        .link_item {
          width: max(10vw, 10vh);

          .image_wrap {
            width: max(8vw, 8vh);
            height: max(8vw, 8vh);
          }

          .image_title {
            font-size: min(2vw, 2vh);
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    &_title {
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin-bottom: 40px;
    }
    .link_list_wrap {
      .link_list {
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;

        .link_item {
          // width: 180px;

          .image_title {
            font-weight: 600;
          }

          .image_wrap {
            // width: 150px;
            // height: 150px;
            margin-bottom: 0;

            img {
              width: 40%;
            }
          }
        }
      }
      .link_list.promot {
        display: grid;
        z-index: 1;
        gap: 18px;
        grid-template-columns: repeat(3, 1fr) !important;
        flex-direction: column;
        max-height: 310px;
      }
    }


  }
  @media screen and (max-width: 660px) {

    margin-bottom: 10px;
    &_title {
      font-size: max(24px, 7.5vw);
      margin-bottom: 40px;
    }

    .link_list_wrap {
      margin-bottom: calc(100vh - 562px);
      gap: 40px;

      .link_list {
        grid-template-columns: repeat(1, 1fr) !important;
        gap: 40px;

        .link_item.desktop {
          display: none !important;
        }
        .link_item.mobile {
          display: flex !important;
        }
      }
      .link_list.promot {
        display: grid;
        z-index: 1;
        grid-gap: 18px;
        gap: 18px;
        grid-template-columns: repeat(3, 1fr) !important;
        flex-direction: column;
        max-height: 310px;
      }
    }

  }
  @media screen and (max-width: 380px) {
    .link_list_wrap {
      .link_list {
        .link_item {
          transform: scale(1.1);
          margin-bottom: 28px;
        }
      }
      .link_list.promot {
        transform: scale(0.85);
        font-size: 0.8em;
      }
    }
    .link_list_wrap {
      margin-bottom: calc(100vh - 462px);
    }
  }
}

// .main{}
  .backdrop{
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 26px;
    z-index: 10000;
    padding-top: 80px;
  }

.lightboxandcover,
.cover {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.lightboxandcover {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .lightBox {
    position: relative;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 10px;
    img {
      max-width: 90vw;
      max-height: 90vh;
    }
  }
  .x-button {
    color: #fff;
    font-weight: 100;
    position: absolute;
    cursor: pointer;
    right: 2rem;
    top: 0;
    margin-top: 2em;
    margin-bottom: 2em;
    svg {
      font-size: 2.5em;
    }
  }
  .next-button svg,
  .prev-button svg {
    font-size: 5rem;
  }
  .cover {
    background: rgba(0, 0, 0, 0.62);
    z-index: 99;
  }
  .prev-button {
    color: #fff;
    font-weight: 100;
    position: absolute;
    cursor: pointer;
    top: 45%;
    transform: translate(40%, -100%);
    margin: 5rem 0;
  }
  .next-button {
    color: #fff;
    font-weight: 100;
    position: absolute;
    cursor: pointer;
    right: 1.5rem;
    top: 45%;
    transform: translate(-40%, -100%);
    margin: 5rem 0;
  }
  @media (max-width: 450px) and (min-width: 300px) {
    .lightBox {
      .next-button {
        right: 0.5rem;
        top: 60%;
        transform: translateY(-100%);
        margin: 1rem 0;
        svg {
          font-size: 3rem;
        }
      }
      .x-button {
        margin: 1rem 0;
        right: 1rem;
        svg {
          font-size: 2rem;
        }
      }
      .prev-button {
        top: 60%;
        transform: translateY(-100%);
        margin: 1rem 0;
        svg {
          font-size: 3rem;
        }
      }
    }
  }
}
.video-intro {
  @media only screen and (max-width: 480px) {
    h2 {
      font-size: 45px;
      color: #424242;
      line-height: 40px;
    }

    h4 {
      font-size: 28px;
      font-style: inherit;
      margin-top: 10px;
    }

    .play-btn {
      display: none;
    }
  }
}
.stationSlider {
  margin-top: 10px;
  max-height: 160px;
  margin-bottom: 20px;
  margin-top: 100px;

  @media screen and (max-width: 980px) {
    margin-top: 0px;
  }

  // @media screen and (max-width: 900px) {
  //   margin-top: 250px ;
  // }

  @media screen and (max-width: 450px) {
    margin-top: 0px;
  }
  .slick-prev:before {
    content: "\2190";
    color: #00aede;
  }
  .slick-next:before {
    content: "\2192";
    color: #00aede;
  }
  .stationImg {
    width: 100% !important;
    height: 150px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      max-width: 100%;
      max-height: 100%;
    }
  }
  .stationImg.toronto {
    img {
      max-width: 100px !important;
    }
  }
  .stationImg.cp {
    img {
      max-width: 100px !important;
    }
  }
  .stationImg.stationNews {
    img {
      max-width: 200px !important;
      max-height: 150px !important;
    }
  }
  .stationImg.stationRadio, .stationImg.kiss  {
    img {
      max-height: 150px !important;
    }
  }
  .stationImg.chch {
    img {
      max-height: 100px !important;
    }
  }
  .stationImg.hamilton {
    img {
      max-height: 100px !important;
    }
  }
  .stationImg.Qstation, .stationImg.newsTalk, .stationImg.cfra{
    img {
      max-height: 200px !important;
    } 
  }
  .slick-track .slick-slide.slick-current + .slick-active, .slick-track .slick-slide {
    transform: scale(0.7) !important;
  }
}
.stationTitle {
  text-align: center;
  color: #424242;
  font-weight: 300;
  text-shadow: 3.993px 3.009px 2px #d8d8d8e6;
  margin-bottom: -20px;
}
.geolocation-tab {
  position: fixed;
  bottom: 0px;
  padding: 12px;
  padding-bottom: 8px;
  left: 40px;
  z-index: 10000;
  background-color: #1d3c5a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: all 0.25s linear;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: rgb(56, 56, 56);
    padding: 15px;
    padding-bottom: 12px;
  }
  span {
    font-size: 16px;
    color: white;
    padding: 5px;
    display: inline-block;
  }
  img {
    width: 24px;
    height: 24px;
    display: inline-block;
    float: left;
    margin-right: 5px;
    @media screen and (max-width:676px){        
      width: 24px;
      height: 24px;
      display: inline-block;
      float: left;
      margin: 8px 5px;
    }
  }
  @media screen and (max-width:767px) {  
    position:  absolute;
    width: 100%;
    left: 0px;
    border-radius: 0px;
    padding-top: 6px;
    bottom: auto;
    top: 0px;

    &:hover {
      padding: 6px 12px;
      padding-bottom: 8px;
    }
  }
}
.flexBox {
  ul.regionList {
    display: flex;
    font-size: 16px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px;
    text-align: center;
    display: none;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    li {
      padding: 20px;
      cursor: pointer;
      border: 1px solid lightblue;
      flex: 1 1;
      display: inline;
      margin: 10px;
      text-align: center;
      border-radius: 5px;
      &.active {
        background: #00b1e3;
        color: white;
      }
    }
  }
}
.red{
  color: red;
}
.blue{
  font-weight: 700;
  color: #45c1ee;
}
.modal {
  .modal-header{
    height:40px;
    margin-bottom: 10px;
    text-align:left;
    @media screen and (max-width: 676px) {
      text-align: center;
      margin-bottom: 0px;
    }
  }
  h2 {    
    padding: 0px;
    margin: 0px;
    text-align: left;
    font-size: 2rem;
    line-height: 2rem;
    @media screen and (max-width: 676px) {
      font-size: 1.25rem;
      margin: 14px 0px 0px;
    }
  }
  .model-img {
    max-width:32px;
    @media screen and (max-width: 676px) {
      width: 32px;
    }
  }
  .postal_input {
    label {
      padding: 0px;
      line-height: 46px;
    }
    input {
      font-size: 15px;
      flex: 1 1;
      // margin-left: 20px;
      width: 60%;
      border: 2px solid gray;
      border-radius: 4px;
      padding: 0px 1rem;
    }
    a {
      cursor: pointer;
      padding: 10px;
      margin-left: 10px;
      width: 40%;
      text-align: center;
    }
    @media screen and (max-width: 676px) {
      // .d-flex{
        flex-direction: column;
      // }

      input, a, label{
        text-align: center;        
        margin-left: 0px;
      }
      a{
        padding:10px 0px;
        width: 100%;
      }
      input{   
        font-size:1.15rem;         
        padding:1rem 2rem;
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
  @media screen and (max-width:676px) {
    .blue-btn{
      width:100%;
    }
    .blue-btn.optional{
      display: block;
    }
  }
}
#apexchat_prechat_invitation_wrapper{
  @media screen and (max-width:989px) {
      display: none!important;
  }
}
.full-width,
.blue-btn {
  box-shadow: 3px 3px 4px #3e3e3e!important;
}
.blue-btn.optional {
  display: none;
}
.full-width{
  margin-left: 15px;
  margin-top: -2px;
}

@media screen and (max-width:767px) {
  .blue-btn.optional{
    display: inline-block;
  }
  #root{
    margin-top: 54px;
  }
}
