#promotinal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 30px;
  max-width: 980px;
  margin: auto;
  position: relative;
  z-index: 2;
  @media screen and (max-width: 940px) {
    padding: 20px;
  }

  h2 {
    text-align: center;
    font-size: 65px;
    line-height: 64px;
    font-weight: 300;
    text-shadow: 3.993px 3.009px 2px hsla(0, 0%, 84.7%, 0.9);
    z-index: 2;
    color: #3cafde;
    @media screen and (max-width: 989px) {
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 39px;
      line-height: 34px;
      font-weight: 300;
    }
  }
  h3 {
    text-align: center;
    font-family: "Quicksand", serif;
    font-size: 2em;
    line-height: 76px;
    color: #1d3c5b;
    font-weight: 400;
    padding: 0 0 30px;
    z-index: 2;
    @media screen and (max-width: 989px) {
      line-height: 46px;
    }
    @media only screen and (max-width: 480px) {
      line-height: 36px;
    }
  }
  h4 {
    text-align: center;
    font-family: "Quicksand", serif;
    font-size: 1.5em;
    line-height: 76px;
    color: #1d3c5b;
    font-weight: 400;
    z-index: 2;
    @media only screen and (max-width: 480px) {
      line-height: 36px;
    }
  }
  .txtContainer p {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    padding: 0 0 20px;
    @media screen and (max-width: 989px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  .imgContainer {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
  .imgContainer img {
    width: 48%;
    margin-bottom: 60px;
    @media screen and (max-width: 940px) {
      width: 100%;
    }
  }
}
#promotinal:before {
  content: "";
  width: 570px;
  height: 240px;
  background: url(../../Assets/images/grid.png) repeat 0 0;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  z-index: 1;
  @media screen and (max-width: 989px) {
    top: 10px;
    height: 100px;
    width: 100%;
  }
}
